import InvestmentPage from './pages/InvestmentPage/InvestmentPage';
import Investment from './pages/InvestmentPageform/Investment';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Checkout from './pages/Checkout/Checkout';

function App() {
  return (
    <BrowserRouter>
    <div className='App'>
      <Routes>
        {/*<Route path='/' element={<Investment/>} />*/}
        {/*<Route path='/home' element={<InvestmentPage/>} />*/}
        {/*<Route path='/checkout' element={<Checkout/>} />*/}
        <Route path='/:id' element={<Checkout/>} />
        <Route path='/invoice/:id' element={<Checkout/>} />
       
      </Routes>
     {/* <InvestmentPage/> */}
    </div>
    </BrowserRouter>
  );
}

export default App;
