import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { baseUrl, invoice_transfer_payment, pay_with_transfer, verify_transfer_transaction } from '../Endpoints'
import { useParams } from 'react-router-dom'
 

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PayTransfer = ({formData, next, prev}) => {
    const [getAcct, setGetAcct] = useState(false)
    const [gacctGenerated, setGacctGenerated] = useState(false);
    const [leverpayUserDetail, setLeverpayUserDetail] = useState({})
    const [mins, setMins] = useState(1)
    const [secs, setSecs] = useState(0)
    const [acct_exp, setAcct_exp] = useState(false);
    const {id} = useParams();

    const toastId = React.useRef(null);
  
    const notify = (message) => {
      if(!toast.isActive(toastId.current)) {
        toastId.current = toast(message);
      }
  
    }
  
    function roundNum(number){
      return +(Math.round(number + "e+2") + "e-2");
   }

   function calculateVat(number,vat){
   const value =  (number/100)* (+vat)
   return roundNum(value)
   }
  
    const dismiss = () =>  toast.dismiss(toastId.current);

    const [transferVerified,setTransferVerified]=useState(false)
    
    const [accountDetailsData, setAccountDetailsData] = useState({
        data:{}
    });
    function Pay(){
        // e.preventDefault()
        notify("Loading,Please wait!")
        
        setGetAcct(true)
        const checkIfUUID =id.split("-")
        if (checkIfUUID.length>1) {
          const getSavedDetails = JSON.parse(localStorage.getItem("LEVERPAY_USER"));
          setLeverpayUserDetail(getSavedDetails)
          axios.post(baseUrl +   invoice_transfer_payment,{...getSavedDetails,access_code:id}, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
            }})
        .then(res=>{
            setGacctGenerated(true)
            setMins(10)
            setSecs(0)
            setAccountDetailsData({
            data:res.data.data
            })
            dismiss()
           
        })
        .catch(err=>{
            dismiss()
            setGetAcct(false)
            toast.error( "Network Error, please try again")
            setGacctGenerated(false)
        })
        }
        else{

        axios.post(baseUrl +   pay_with_transfer,{access_code:id}, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
            }})
        .then(res=>{
            setGacctGenerated(true)
            setMins(10)
            setSecs(0)
            setAccountDetailsData({
            data:res.data.data
            })
            dismiss()
           
        })
        .catch(err=>{
            dismiss()
            setGetAcct(false)
            toast.error( "Network Error, please try again")
            setGacctGenerated(false)
        })
      }
    };


    const verifyTransferTransaction  = () =>{
        axios.post(baseUrl + verify_transfer_transaction,{uuid:accountDetailsData.data?.uuid,amount:formData.data.amount||accountDetailsData.data?.amount,account:accountDetailsData.data?.accountNumber}, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
            }})
        .then(res=>{
            setTransferVerified(true)
            next();
           
        })
        .catch(err=>{
            dismiss()
            toast.error( err.response.data.message)
            setTransferVerified(false);
        })
    }




    const [copySuccess, setCopySuccess] = useState('');

// your function to copy here

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(accountDetailsData.data?.accountNumber);
      notify("Copied to clipboard")
      setCopySuccess('Copied!');
      setTimeout(() => {
        dismiss()
      }, 1000);
      
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  }

    useEffect(() => {
        const timer = setInterval(() => {
          if (mins === 0 && secs === 0) {
            clearInterval(timer);
            setAcct_exp(true)
            setGacctGenerated(false)
            setGetAcct(false)
            // Timer has reached 0, you can trigger some action here.
          } else {
            if (secs === 0) {
              setMins(mins - 1);
              setSecs(59);
            } else {
              setSecs(secs - 1);
            }
          }
        }, 1000);
    
        return () => clearInterval(timer);
      }, [mins, secs]);

  return (
    <section className='CO_brkdwn'>
    <div className='orderbrkdwn'>
        <h2>Order Breakdown</h2>
        <div className="orderBreakdown">
            <div className="eachRow">
                <span className="rowName">
                Company 
                </span>
                <span className="rowContent">
                <strong>{formData?.data?.merchant?.business_name||formData?.data?.merchant?.merchant?.business_name}</strong>
                </span>
            </div>
            {formData?.data?.merchant_reference &&<div className="eachRow">
                <span className="rowName">
                Order Number
                </span>
                <span className="rowContent">
                <strong>{formData?.data?.merchant_reference}</strong>
                </span>
            </div>}
            <div className="eachRow">
                <span className="rowName">
                Product 
                </span>
                <span className="rowContent">
                <strong> {formData?.data?.product ||formData?.data?.product_description }</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                Amount 
                </span>
                <span className="rowContent">
                <strong>NGN {roundNum(formData?.data?.amount ||formData?.data?.price ||0.00)}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                VAT ({roundNum(formData?.data?.vat||0.00)})%
                </span>
                <span className="rowContent">
                <strong>NGN {calculateVat(formData?.data?.amount ||formData?.data?.price ||0,formData?.data?.vat)}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                Transaction fee (1.3%) 
                </span>
                <span className="rowContent">
                <strong>NGN {roundNum(formData?.data?.fee || 0.00)}</strong>
                </span>
            </div>
        </div>
    </div>
    <div className='eachRow'>
        <span className="rowName">TOTAL:  </span>
        <span className="rowContent"> NGN {roundNum(formData?.data?.total||0.00)}</span>
    </div>
   
    {
        !gacctGenerated &&  <div className='payNow'>
        <button onClick={()=>{
            Pay()
        }}>
            {getAcct ? <img src='/images/load.png' alt='load' className='load' />: 'Pay Now '}  
        </button>
        <button onClick={prev} style={{backgroundColor: 'white', color:'black', textDecoration: 'Underline'}} >
            Go back
        </button>
    </div>
    }
    {
        gacctGenerated && <>
          <div className='acct_det'>
        
                <div className='accountDetailBox'>
                    <span className='rowName2'>Bank name: </span>
                    <span className='rowContent2'>{accountDetailsData.data?.bank} </span>
                </div>
                <div className='accountDetailBox'>
                    <span className='rowName2'>Account name: </span>
                    <span className='rowContent2'>{accountDetailsData.data?.accountName}  </span>
                </div>
                <div className='accountDetailBox'>
                    <span className='rowName2'>Account number: </span>
                    <span className='rowContent2'>{accountDetailsData.data?.accountNumber}  <img src="/images/copy.png" alt="" onClick={copyToClipBoard}  style={{cursor:'Pointer'}}/></span>
                </div>
                <div className='accountDetailBox'>
                    <span className='rowName2'>Amount: </span>
                    <span className='rowContent2' >NGN {roundNum(formData.data.total||0.00)} </span>
                </div>
                
        </div>
            <strong className='CO_exp'>
                Account will expire in : <span style={{color:'red'}}>{mins}:{secs}</span> 
            </strong>
            <div className='payNow'>
                <button onClick={verifyTransferTransaction}>
                Payment completed
                </button>
            </div>
            <ToastContainer/>
        </>
       
    }
   
</section>
  )
}

export default PayTransfer