import React, { useEffect } from 'react'
import { useState } from 'react'
import CheckoutTransfer from '../CheckoutTransfer/CheckoutTransfer'
import PayTransfer from './PayTransfer'
import PayCard from './PayCard'
import { useParams } from 'react-router-dom'

const CheckoutPayment = ({formData, next, prev, opacity}) => {
    const [payMethod, setPayMethod] = useState(true)
    const [transfer, setTransfer] = useState(true)
    const nav_style = {
        borderRight: '1px solid gainsboro',
        borderLeft: '1px solid gainsboro',
        borderBottom: '3px solid #082e88',
        color: ' #082e88',
        cursor:'pointer',
        fontSize:'14px'
    }

    const general_style={
        border:'none',
        cursor:'pointer',
        fontSize:'14px'
    }
    
    
    
    const {id} = useParams();
    const [checkIfIdUUID,setCheckIfIdUUID]=useState(false)
 
    const fetchData = async () => {
        const checkIfUUID =id.split("-")
        if (checkIfUUID.length>1) {
            setCheckIfIdUUID(true)
        }else{
            setCheckIfIdUUID(false)
        }}
    useEffect(() => {
        fetchData();
      }, []);

    return (
        <div className='CO_pay'>
            <nav>
                <ul>
                    <li  style={payMethod ? nav_style : general_style} 
                    onClick={() => {
                         setPayMethod(true) 
                         setTransfer(true)
                         }}>
                        Pay with Transfer
                    </li>
                  <li style={payMethod ? general_style : nav_style} 
                    onClick={() => { 
                        if (checkIfIdUUID) {
                            return
                        }
                        setPayMethod(false)
                        setTransfer(false)
                         }}>
                        Pay with Card
                    </li>
                </ul>
            </nav>
            {
                transfer &&  <section style={{padding:'0'}}>
                    <PayTransfer formData={formData} next={next} prev={prev} />
                </section>
            }
            {
                !transfer &&  <section>
                <PayCard formData={formData} next={next} prev={prev} opacity={opacity}  />
                </section>
            }
           
        </div>
    )
}

export default CheckoutPayment