import React from 'react'
import './CheckoutForm.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { baseUrl, pay_with_card, verify_card_token } from '../Endpoints'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PayCard = ({formData, next, prev}) => {


     

    const toastId = React.useRef(null);
    const notify = (message) => {
      if(!toast.isActive(toastId.current)) {
        toastId.current = toast(message);
      }
  
    }
  
    const dismiss = () =>  toast.dismiss(toastId.current);

    const [transferVerified,setTransferVerified]=useState(false)
    const [getAcct, setGetAcct] = useState(false)
    const [cardDetails, setCardDetails] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [paymentResponse, setPaymentResponse] = useState('');
    const {id} = useParams();
    const [otpCOnf, setOtpCOnf] = useState({
        payment_id: '',
        otp: ''
    })
    const [cardData, setCardData] = useState({
        card_no: '',
        card_name: 'Leverpay',
        card_exp: '11/2026',
        card_cvv: ''
    })
    function handleChange(e) {
        setCardData({
            ...cardData,
            [e.target.name]: e.target.value
        })
    }


 
    useEffect(() => {
        setCardDetails(cardData.card_no.toString())
        // if(cardData.card_no.slice(0,))
    }, [cardData])

    function Pay(e){
        notify("Processing, Please wait!")
        e.preventDefault();
        setGetAcct(true);
        const cardExp =cardData.card_exp.split("/");
        const cardExpe2 = `${cardExp[0]}/20${cardExp[1]}`;

    
         axios.post(baseUrl +  pay_with_card, {card_number:cardData.card_no, cvv:cardData.card_cvv,expiry:cardData.card_exp,access_code:id}, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
              'content-type': 'text/json'
            }
          })
        .then(res=>{
            console.log(res,'.................res');
            dismiss()
            setOtpCOnf({
                ...otpCOnf,
                payment_id:res.data.data.payment_id
            })
            setPaymentResponse(res.data.data.payment_id)
            document.body.classList.add('no-scroll')
            setShowModal(true)
        })
        .catch(err=>{
            console.log(err)
            dismiss()
            toast.error(err.response.data.message)
            setGetAcct(false)
            document.body.classList.remove('no-scroll')
            setShowModal(false)
        })
    }

    function handleOtpChange(e){
        setOtpCOnf({
            ...otpCOnf,
            [e.target.name]: e.target.value
        })
    }

    function confirmPayment(e){
        if (otpCOnf.otp.length < 4) {
            return
        }
        notify("Processing, Please wait!")
        e.preventDefault()
        setConfirm(true)

        
        axios.post(baseUrl +  verify_card_token, {...otpCOnf}, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
              'content-type': 'text/json'
            }
          })
        .then(res=>{
            dismiss()
            setPaymentResponse(res.data.data)
            next()
        })
        .catch(err=>{
            console.log(err);
            dismiss()
            toast.error(err.response.data.message)
            setConfirm(false)
        })
    }


    function cancelPaymentButton(){
        setShowModal(false);
        setConfirm(false);
    }

    return (
        <div className='CO_brkdwn'>
          <div className='orderbrkdwn'>
        <h2>Order Breakdown</h2>
        <div className="orderBreakdown">
            <div className="eachRow">
                <span className="rowName">
                Company 
                </span>
                <span className="rowContent">
                <strong>{formData?.data?.merchant?.business_name}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                Order Number
                </span>
                <span className="rowContent">
                <strong>{formData?.data?.merchant_reference}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                Product 
                </span>
                <span className="rowContent">
                <strong> {formData?.data?.product}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                Amount 
                </span>
                <span className="rowContent">
                <strong>NGN {formData?.data?.amount}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                VAT 
                </span>
                <span className="rowContent">
                <strong>NGN {formData?.data?.vat}</strong>
                </span>
            </div>
            <div className="eachRow">
                <span className="rowName">
                Transaction fee (1.3%) 
                </span>
                <span className="rowContent">
                <strong>NGN {formData?.data?.fee}</strong>
                </span>
            </div>
        </div>
    </div>
    <div className='eachRow'>
        <span className="rowName">TOTAL:  </span>
        <span className="rowContent"> NGN {formData?.data?.total}</span>
    </div>
            
            <form className='payCard' onSubmit={Pay}>
                <main style={{
                    backgroundImage: cardDetails ? cardDetails.slice(0, 2) === '22' ? "url('/images/ GreenCard.png')" : cardDetails.slice(0, 2) === '10' ? "url('/images/CardGold.png')" : cardDetails.slice(0, 2) === '23' ? 'url("/images/diamond.png")' : cardDetails.slice(0, 2) === '94' ? 'url("/images/CardpinkLady.png")' : cardDetails.slice(0, 2) === '65' ? 'url("/images/CardEnterprise.png")' : '' : '',
                    color: cardDetails ? cardDetails.slice(0, 2) === '22' ? 'black' :  cardDetails.slice(0, 2) === '10' ? 'white' : cardDetails.slice(0, 2) === '23' ? 'white' : cardDetails.slice(0, 2) === '94' ? 'white' : cardDetails.slice(0, 2) === '65' ? 'white' : 'black' : 'black',
                    maxWidth:"300px",
                    minWidth:"220px"
                }}>
                    <div>
                        <img src="/images/chip.png" alt="chip" />
                        <img src={cardDetails ? cardDetails.slice(0, 2) === '22' ? '/images/logoW.png'  :cardDetails.slice(0, 2) === '10' ? '/images/logoW.png' : cardDetails.slice(0, 2) === '23' ? '/images/logoW.png' : cardDetails.slice(0, 2) === '94' ? '/images/logoW.png' : cardDetails.slice(0, 2) === '65' ? '/images/logoW.png' : '/images/logo.png' : '/images/logo.png'} alt="" />
                    </div>
                    <div>
                        <p className='cardText'>
                            {
                                cardDetails && <>
                                  <span className='cardText'>  {cardDetails.slice(0, 4)} {cardDetails.slice(4, 8)} {cardDetails.slice(8, 12)} {cardDetails.slice(12, 16)}</span>
                                </>
                            }
                            {
                                !cardDetails && <>
                                   <span className='cardText'> 0000 0000 0000 0000</span>
                                </>
                            }
                        </p>
                    </div>
                    <div>
                        <h4 className='cardText'>Leverpay</h4>
                        <span>
                            <small>Valid Thru</small>
                            <small>{cardData.card_exp}</small>
                        </span>
                    </div>
                </main>
                <article>
                    <label className='cardText'>
                        CARD NUMBER {cardDetails ? cardDetails.slice(0, 2) === '22' ? '(Leverpay Card)' : cardDetails.slice(0, 2) === '10' ? '(Leverpay Card)' : cardDetails.slice(0, 2) === '23' ? '(Leverpay Card)' : cardDetails.slice(0, 2) === '94' ? '(Leverpay Card)' : cardDetails.slice(0, 2) === '65' ? '(Leverpay Card)' : '(Not a Leverpay Card)' : ''}
                        <input
                            type="number"
                            name='card_no'
                            onChange={handleChange}
                            value={cardData.card_no.slice(0, 16)}
                            required={true}
                            style={{
                                textAlign: 'center'
                            }}
                        />
                    </label>
                    <div>

                        <label className='cardText'>
                            CVV
                            <input
                                type="number"
                                name='card_cvv'
                                onChange={handleChange}
                                value={cardData.card_cvv.slice(0, 3)}
                                required={true}
                                placeholder='123'
                                maxLength={3}
                            />
                        </label>
                        <label className='cardText'>
                            VALID TILL
                            <input
                                type="text"
                                name='card_exp'
                                onChange={handleChange}
                                value={cardData.card_exp}
                                required={true}
                                placeholder='11/2026'
                                maxLength={7}
                            />
                        </label>
                    </div>
                </article>
                <div className='payNow'>
                    <button type='submit'>
                        {getAcct ? <img src='/images/load.png' alt='load' className='load' /> : 'Pay Now '}
                    </button>
                    <button onClick={prev} style={{ backgroundColor: 'white', color: 'black', textDecoration: 'Underline' }} >
                        Go back
                    </button>
                </div>
            </form>
            {
                showModal &&       <div className='modal'>
                <div className='modal-header-picture'>
                  <div className='modal-picture-circle'>
                    <span className='imgPic'>
                    <img src="/images/Group 316.svg" alt="" />
                      </span>  
                  </div>
                </div>
            <form className='otpForm' onSubmit={confirmPayment}>
                <label className='otpText'>
                    Please Input the 4 digits OTP sent <br /> to your Email or phone number <br /> to confirm this transaction 
                    </label>
                  <div className='otpDiv'>

                  <input 
                       type="number"
                       value={otpCOnf.otp.slice(0,4)}
                       onChange={handleOtpChange}
                       name='otp' 
                       placeholder='OTP'
                       required
                    className='otpInput'
                  
                    />
                
                <button type='submit' className='otpVerifyButton'>
                {confirm ? <img src='/images/load.png' alt='load' className='load' /> : 'Approve'}
                </button>
                </div>

                <label className='otpText'>
                A Token should be sent to <br /> you within 5 minutes
                </label>
                <div className='otpCancelPayment' onClick={cancelPaymentButton}>
                  <strong>X</strong>
                  <small>Cancel payment</small>
                </div>

                <div className='imgSecure'>
                  <img src="/images/secures.svg" alt="" width='150px' />
                </div>
            </form>
        </div> 
            }
            

      
        </div>
    )
}

export default PayCard