import axios from "axios";

export const baseUrl = "https://leverpay-api.azurewebsites.net/api/v1";
export const fetchInfo = async (props) => {
  const response = await axios.get(baseUrl + props.endPoint);
  return response.data;
};


export const verify_Merchant_Access_Token = "/leverchain/transaction/verify-request";

export const initial_transaction = "/leverchain/transaction/initialize";


export const save_customer_details = "/leverchain/transaction/save-details";


export const pay_with_transfer = "/leverchain/transaction/pay-with-transfer";


export const pay_with_card ="/leverchain/transaction/pay-with-card";


export const verify_card_token ="/leverchain/transaction/verify-card-otp";


export const verify_transfer_transaction = "/verify-transfer-transaction"

export const get_invoice_for_non_leverpay_user ="/invoice"

export const invoice_transfer_payment  = "/invoice-transfer-payment/"





 





