import React, { useEffect } from 'react'
import './CheckoutForm.css'
import Form from './Form'
import { useState } from 'react'
import CheckoutPayment from './CheckoutPayment'
import PaymentSuccess from './PaymentSuccess'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { baseUrl, get_invoice_for_non_leverpay_user, save_customer_details, verify_Merchant_Access_Token } from '../Endpoints'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CheckoutForm = () => {


  const toastId = React.useRef(null);
  
  const notify = (message) => {
    if(! toast.isActive(toastId.current)) {
      toastId.current = toast(message);
    }

  }

  function roundNum(number){
    return +(Math.round(number + "e+2") + "e-2");
 }

  const dismiss = () =>  toast.dismiss(toastId.current);

    const {id} = useParams();
    const [checkIfIdUUID,setCheckIfIdUUID]=useState(false)
    const [checkIfTrue, setCheckIfTrue]=useState(false)
    const [ merchantDetails, setMerchantDetails] = useState({
        data:{},
        loading:true
    })
 

    const fetchData = async () => {
      notify("Loading, Please wait")
      const checkIfUUID =id.split("-")
      if (checkIfUUID.length>1) {
        setCheckIfIdUUID(true);
        try {
          const response = await axios.get(baseUrl +  get_invoice_for_non_leverpay_user + `/${id}`, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
            }}); // Replace with your API endpoint

            let data = response.data?.data;
               setMerchantDetails({
                    data,
                    loading:false
               })
               dismiss()
        } catch (error) {
          // toast.error( error.response.data.message)
          console.error(error);
        }
    
      }else{
        try {
          const response = await axios.get(baseUrl +  verify_Merchant_Access_Token+ `/${id}`, {
            headers: {
              Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
            }}); // Replace with your API endpoint

            let data = response.data?.data;
               setMerchantDetails({
                    data,
                    loading:false
               })
               dismiss()
        } catch (error) {
          toast.error( error.response.data.message)
          console.error(error);
        }
      }

     
   
     
      };

      const saveCustomDetails= async() => {
        toast.info("Loading Please wait")
        setMerchantDetails({
            ...merchantDetails,
            loading:true
        })
        let payload={
            ...formData,
            first_name:formData.firstname,
            last_name:formData.lastname,
            access_code:id
        }

        delete payload.firstname;
        delete payload.lastname;

        const checkIfUUID =id.split("-")
        if (checkIfUUID.length>1) {
            delete payload.access_code
            let payload2 ={
              first_name:payload.first_name,
              last_name:payload. last_name,
              phone:payload.phone,
              uuid:merchantDetails.data.uuid
            }

            localStorage.setItem("LEVERPAY_USER",JSON.stringify(payload2));
       
            nextStep()
        }
        else{

   
        try {
           await axios.post(baseUrl + save_customer_details,   {...payload}, {
              headers: {
                Authorization: `Bearer fyi6hygcrfe7x5caq8ojmx4kgoww4zubambiabok`,
                'content-type': 'text/json'
              }
            }).then((response)=>{
       
              if (response.status==200) {   
                dismiss() 
                nextStep()
              }
          
            }).catch((err)=>{
          
              toast.error( err.response.data.message)
              setCheckIfTrue(false)
  
            })
             
          } catch (error) {
            console.error(error);
            toast.error( error.response.data.message)
            setCheckIfTrue(false)
            return true
          }
        }
      }
      useEffect(() => {
        fetchData();
      }, []);

  
    const [step, setStep] = useState(1)
    async function nextStep(){
 
          dismiss();
          setStep(step+1)
     
  
  
 
    }
    function prevStep(){
        setStep(step-1)
    }
    const [formData, setFormData] = useState({
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
       
    })

    function handleChange(e){
        setFormData({
            ...formData,
            [e.target.name]: e.target.value 
        })


    }
 

  return (
    <div className='CO_form'>
        <section className='C-logo'>
            <img src="/images/leverpay_logo.png" alt="" />
        </section>
        <section className='C-wel'>
            <h1>
                Welcome! 
           
            </h1>
            <small>Amount: </small>
            <strong>NGN {roundNum(merchantDetails.data.total||0)}</strong>
        </section>
        {
            step === 1 &&  <Form 
            email={formData.email}
            fname={formData.firstname}
            lname={formData.lastname}
            no={formData.phone}
            onchange={handleChange}
            next={saveCustomDetails}
            prev={prevStep}
         />
        }
        {
            step === 2 && <CheckoutPayment
            next={nextStep}
            prev={prevStep}
            formData={merchantDetails}
            />
        }
        {
            step === 3 && <PaymentSuccess    formData={merchantDetails.data.total}/>
        }
       
        <section className='WIL'>
            <img src="/images/secures.png" alt="" />
            <br /><br />
            <h3><a href="https://leverpay.io" target="_blank">Learn more about Leverpay?  </a></h3>
        </section>
        <ToastContainer/>
    </div>
  )
}

export default CheckoutForm