import React from 'react'
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm'
import './Checkout.css'
 

const Checkout = () => {
  return (
    <div className='checkoutPage'>
        <CheckoutForm />
    </div>
  )
}

export default Checkout