import React, { useEffect } from 'react'
import './CheckoutForm.css';


const Form = ({email, fname, lname, no, onchange, next}) => {

  

    function proceed (e){
        e.preventDefault()
        next()
    }
  return (
    <div className='C_form'>
          <form onSubmit={proceed} className='formCss'>
            <div>
                <label>
                    Email:    
                    <input 
                    type="email"
                    value={email}
                    onChange={onchange}
                    name='email'
                    required={true}
                    className='formInput'
            
                    />
                </label>
            </div>
            <div>
                <label>
                    Firstname:  
                    <input 
                    type="text"
                    value={fname}
                    onChange={onchange}
                    name='firstname'
                    required={true}
                    className='formInput'
                    />
                </label>
                <label>
                    Lastname:  
                    <input 
                    type="text"
                    value={lname}
                    onChange={onchange}
                    name='lastname'
                    required={true}
                    className='formInput'
                    />
                </label>
            </div>
            <div>
                <label>
                    Phone Number:  
                    <input 
                    type="number"
                    value={no}
                    onChange={onchange}
                    name='phone'
                    required={true}
                    className='formInput'
                    />
                </label>
            </div>
            <div>
                <button type='submit' className='formInput'>
                    Proceed
                </button>
            </div>
        </form>
 

    </div>
  )
}

export default Form