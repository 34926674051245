import React from 'react'
import { useState } from 'react'
import SuccessCheckmark from '../success/SuccessCheckmark'

const PaymentSuccess = ({formData}) => {
    const [paymentConfirmed, setPaymentConfirmed] = useState(true)

    function goBackToDashboard(){
        localStorage.removeItem("LEVERPAY_USER")
        window.location.reload()
    }
  return (
    <div className='CO_paymentSUcess'>
        {
            !paymentConfirmed && <main>
                <img src="/images/load2.png" style={{opacity:'.7', width:'70px'}} className='load' alt="load" />
                <h3>
                    Your Payment is being confirmed...  
                </h3>
            </main>
        }
        {
            paymentConfirmed && <main>
                <img src="/images/success.png" style={{opacity:'.7', width:'70px'}} alt="load" />
                <h3>
                    Your Payment was Successful   
                </h3>
            </main>
        }
        <div className='otpSuccess' onClick={goBackToDashboard}>
                
                  <small>Go back</small>
                </div>

    </div>
  )
}

export default PaymentSuccess